<!--
 * @Description: 原路回退审核
 * @Author: 琢磨先生
 * @Date: 2022-10-30 11:57:13
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-30 12:18:15
-->
<template>
  <el-tabs>
    <el-tab-pane label="待审核"></el-tab-pane>
  </el-tabs>
  <pending></pending>

</template>

<script>
import pending from "./pending";

export default {
      components: {
    pending,
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
 
  },
};
</script>

<style  scoped>
</style>